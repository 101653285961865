$(document).on('turbolinks:load', function () {

  const slideLink = document.querySelectorAll('.slide-link');

  slideLink.forEach((item) => {
    item.children[0].addEventListener('click', function (e) {
      e.preventDefault();
      downloadImage(e.target.parentNode.getAttribute("src"));
    })
  });

  function downloadImage(url) {
    fetch(url, {
        mode: 'no-cors',
      })
      .then(response => response.blob())
      .then(blob => {
        let blobUrl = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.download = url.replace(/^.*[\\\/]/, '');
        a.href = blobUrl;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
  }


});
