$(document).on('turbolinks:load', function () {

	let element_sell = document.getElementById("demand_transaction_types_sell") || document.getElementById("offer_transaction_types_sell");
	let element_rent = document.getElementById("demand_transaction_types_rent") || document.getElementById("offer_transaction_types_rent");

	if (element_sell) {
		element_rent.addEventListener('change', (element) => {
			isChecked()
		});

		element_sell.addEventListener('change', (element) => {
			isChecked()
		});

		function isChecked() {
			if (!element_sell.checked && !element_rent.checked) {
				element_sell.setAttribute("required", true)
				element_rent.setAttribute("required", true)
			} else {
				element_sell.removeAttribute("required", true)
				element_rent.removeAttribute("required", true)
			}
		}

		isChecked()
	}
})